import(/* webpackMode: "eager", webpackExports: ["LexicalEditorTheme"] */ "/app/node_modules/@payloadcms/richtext-lexical/dist/lexical/theme/EditorTheme.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"../src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/layout.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollToTop"] */ "/app/src/components/ScrollToTop/ScrollToTop.tsx");
